import Alpine from "alpinejs";

export const popup = {
    visible: false,
    priceDiamond: false,
    priceGold: false,
    valueGold: '',
    valueDiamond: '',
    id: '',
    show() {
        this.visible = true;
        Alpine.store("stopScroll").enable();
        if (this.id === 'starndart') {
            this.priceGold = true
            this.valueGold = '9000₴ / 11 000₴ (Starndart)'
        }
        if (this.id === 'vip') {
            this.priceDiamond = true
            this.valueDiamond = '17 000₴ / 19 000₴ (VIP)'
        }
    },
    hide() {
        this.visible = false;
        Alpine.store("stopScroll").disable();
        this.priceDiamond = false;
        this.priceGold = false;
        this.valueGold = '';
        this.this.valueDiamond = '';
        this.id = '';
    }
};
