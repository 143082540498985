export const lessons_content = [
    {
        id: 1,
        title: "дізнаєтеся, яка ваша роль жінки і чи відповідає вона вам",
        img: "./img/done.svg",
    },
    {
        id: 2,
        title: "з чим вам страшно розпрощатися і чому",
        img: "./img/done.svg",
    },
    {
        id: 3,
        title: "усвідомите силу любові та як саме ви її не приймаєте",
        img: "./img/done.svg",
    },
    {
        id: 4,
        title: "дасте собі обіцянку витримати та дійти курс до кінця",
        img: "./img/done.svg",
    },
    {
        id: 5,
        title: "відчуєте єднання з іншими учасницями та дозволите собі бути неідеальною, справжньою",
        img: "./img/done.svg",
    },
    {
        id: 6,
        title: "навчитеся розпізнавати де і в чому ви себе обманюєте",
        img: "./img/done.svg",
    },
];

