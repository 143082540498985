export const program_content = [
    {
        id: 1,
        title: "Твоя жіноча роль",
        text: "Відкриття та Заповнення",
        text2: "Розкрийте свою унікальну роль як жінки в сучасному світі, відкривши свій потенціал і заповнюючи його сміливо та відповідально",
        img: "./img/01.svg",
    },
    {
        id: 2,
        title: "Від жертви до героїні",
        text: "Вихід з ролі жертви, перетворюючи себе на сильну і самостійну жінку, готову приймати власні рішення і контролювати своє життя",
        text2: "",
        img: "./img/02.svg",
    },
    {
        id: 3,
        title: "Хороша дівчинка і що з нею не так: Розкриття Ілюзій",
        text: "Виявлення внутрішніх потреб та бажань, розвиток особистості та самовираження",
        text2: "",
        img: "./img/03.svg",
    },
    {
        id: 4,
        title: "Твої кордони та вразливість: Захист і взаємодія",
        text: "Установлення здорових границь та відкриття власної вразливості як джерела сили та зв'язку",
        text2: "",
        img: "./img/04.svg",
    },
    {
        id: 5,
        title: "Стосунки, або чому воно не клеїться",
        text: "Побудова здорових взаємин",
        text2: "Вивчення принципів здорових стосунків та навчання ефективним способам спілкування та взаємодії",
        img: "./img/05.svg",
    },
    {
        id: 6,
        title: "Мрії та реальність",
        text: "Планування майбутнього. Подорож до Самореалізації",
        text2: "Розвиток планів та стратегій досягнення цілей та реалізації мрій",
        img: "./img/06.svg",
    },
    {
        id: 7,
        title: "Жінка і фінанси",
        text: "Самостійність та Фінансова Незалежність",
        text2: "Освоєння основних фінансових принципів та стратегій досягнення фінансової стабільності. Магія грошей",
        img: "./img/07.svg",
    },
    {
        id: 8,
        title: "Досить брехні - про самообман: Правда і Відкриття",
        text: "Розкриття та прийняття правди про себе та навколишній світ, відмова від самообману та міфів",
        text2: "",
        img: "./img/08.svg",
    },
    {
        id: 9,
        title: "План Б - я у себе є",
        text: "Створення запасних планів та підтримка себе",
        text2: "Розробка стратегій для подолання труднощів та підтримка себе в непередбачених ситуаціях",
        img: "./img/09.svg",
    },
];

