import Swiper, { Pagination, Navigation, Autoplay } from "swiper";
Swiper.use([Pagination, Navigation, Autoplay]);

import { program_content } from "../vendor/program-content.js";
import { advances_content } from "../vendor/advances-content.js";
import { lessons_content } from "../vendor/lessons-content.js";
import { reviews_content } from "../vendor/reviews-content.js";
import Alpine from "alpinejs";

export const content = () => ({
    program_content,
    advances_content,
    lessons_content,
    reviews_content,
    sliderMain: null,
    sliderModal: null,
    idSlide: null,
    openModal: false,

    init() {
        document.addEventListener("DOMContentLoaded", () => {
            this.initSwiper();
            this.program_content = program_content;
            this.advances_content = advances_content;
            this.lessons_content = lessons_content;
            this.reviews_content = reviews_content;
        });
    },

    show() {
        this.openModal = true;
        Alpine.store("stopScroll").enable();
    },

    hide() {
        this.openModal = false;
        Alpine.store("stopScroll").disable();
    },

    initSwiper() {
        this.sliderMain = new Swiper(this.$refs["reviews-swiper"], {
            // loop: true,
            slidesPerView: 2,
            spaceBetween: 15,
            // allowTouchMove: false,
            speed: 6000,
            freeMode: true,
            autoplay: {
                delay: 0,
                disableOnInteraction: false,
            },
            breakpoints: {
                768: {
                    slidesPerView: 3,
                },
                992: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                },
            },
        });

        this.sliderModal = new Swiper(this.$refs["reviews-swiper-modal"], {
            slidesPerView: 1,
            initialSlide: this.idSlide,
            spaceBetween: 15,
            // allowTouchMove: false,
            speed: 1000,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
        });
    },
});
