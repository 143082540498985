export const reviews_content = [
    // {
    //     id: 1,
    //     img: "./img/reviews/1.jpg",
    // },
    {
        id: 2,
        img: "./img/reviews/2.jpg",
    },
    {
        id: 3,
        img: "./img/reviews/3.jpg",
    },
    {
        id: 4,
        img: "./img/reviews/4.jpg",
    },
    {
        id: 5,
        img: "./img/reviews/5.jpg",
    },
    {
        id: 6,
        img: "./img/reviews/6.jpg",
    },
    {
        id: 7,
        img: "./img/reviews/7.jpg",
    },
    {
        id: 8,
        img: "./img/reviews/8.jpg",
    },
    // {
    //     id: 9,
    //     img: "./img/reviews/9.jpg",
    // },
    // {
    //     id: 10,
    //     img: "./img/reviews/10.jpg",
    // },
    // {
    //     id: 11,
    //     img: "./img/reviews/11.jpg",
    // },
    // {
    //     id: 12,
    //     img: "./img/reviews/12.jpg",
    // },
    // {
    //     id: 13,
    //     img: "./img/reviews/13.jpg",
    // },
    // {
    //     id: 14,
    //     img: "./img/reviews/14.jpg",
    // },
    // {
    //     id: 15,
    //     img: "./img/reviews/15.jpg",
    // },
    // {
    //     id: 16,
    //     img: "./img/reviews/16.jpg",
    // },
    // {
    //     id: 17,
    //     img: "./img/reviews/17.jpg",
    // },
    // {
    //     id: 18,
    //     img: "./img/reviews/18.jpg",
    // },
    // {
    //     id: 19,
    //     img: "./img/reviews/19.jpg",
    // },
    // {
    //     id: 20,
    //     img: "./img/reviews/20.jpg",
    // },
    // {
    //     id: 21,
    //     img: "./img/reviews/21.jpg",
    // },
    // {
    //     id: 22,
    //     img: "./img/reviews/22.jpg",
    // },
    // {
    //     id: 23,
    //     img: "./img/reviews/23.jpg",
    // },
    // {
    //     id: 24,
    //     img: "./img/reviews/24.jpg",
    // },
    // {
    //     id: 25,
    //     img: "./img/reviews/25.jpg",
    // },
    // {
    //     id: 26,
    //     img: "./img/reviews/26.jpg",
    // },
    // {
    //     id: 27,
    //     img: "./img/reviews/27.jpg",
    // },
    {
        id: 28,
        img: "./img/reviews/28.jpg",
    },
    {
        id: 29,
        img: "./img/reviews/29.jpg",
    },
    {
        id: 30,
        img: "./img/reviews/30.jpg",
    },
    {
        id: 31,
        img: "./img/reviews/31.jpg",
    },
    {
        id: 32,
        img: "./img/reviews/32.jpg",
    },
    {
        id: 33,
        img: "./img/reviews/33.jpg",
    },
    {
        id: 34,
        img: "./img/reviews/34.jpg",
    },
    {
        id: 35,
        img: "./img/reviews/35.jpg",
    },
    {
        id: 36,
        img: "./img/reviews/36.jpg",
    },
    {
        id: 37,
        img: "./img/reviews/37.jpg",
    },
];

