import Alpine from "alpinejs";

export const pay = {
    visible: false,
    text: '',
    text2: '',
    init() {
        document.addEventListener('DOMContentLoaded', () => {
            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.has('success')) {
                this.show();
                this.text = 'Успішно ✅';
                this.text2 = 'До зустрічі в телеграмі💌';
            }
            if (urlParams.has('fail')) {
                this.show();
                this.text = 'Помилка при оплате'
            }
        });
    },
    show() {
        this.visible = true;
        Alpine.store("stopScroll").enable();
    },
    hide() {
        this.visible = false;
        Alpine.store("stopScroll").disable();
        const url = new URL(window.location.href);
        url.searchParams.delete('success');
        window.history.replaceState({}, document.title, url);
    }
};
