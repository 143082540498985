import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import {mobileMenu} from './store/mobile-menu';
import {stopScroll} from './store/stop-scroll';
import {content} from "./components/content";
import {counter} from "./components/counter";
import {popup} from "./store/popup";
import {pay} from "./store/pay";




document.addEventListener('alpine:init', () => {
    Alpine.plugin(collapse);

    Alpine.data("content", content);
    Alpine.data("counter", counter);

    Alpine.store("mobileMenu", mobileMenu);
    Alpine.store("stopScroll", stopScroll);
    Alpine.store("popup", popup);
    Alpine.store("pay", pay);
});


window.Alpine = Alpine

Alpine.start();

const headerElement = document.querySelector('header');
const headerHeight = headerElement.offsetHeight;

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        const target = document.querySelector(this.getAttribute('href'));

        if (target) {
            const targetPosition = target.getBoundingClientRect().top + window.scrollY;

            window.scrollTo({
                top: targetPosition - headerHeight,
                behavior: 'smooth'
            });
        }
    });
});

"use strict"

document.addEventListener('DOMContentLoaded', function () {
    const form = document.querySelector('#form');
    form.addEventListener('submit', formSend);

    async function formSend(e) {
        e.preventDefault();

        let error = formValidate(form);

        let formData = new FormData(form);
        console.log(formData)

        if (error === 0) {
            form.classList.add('_sending');
            let response = await fetch('sendmail.php', {
                method: 'POST',
                body: formData
            });
            console.log(response)
            if (response.ok) {
                let result = await response.json();
                alert(result.message);
                form.reset()
                form.classList.remove('_sending');
            } else {
                alert('Помилка');
            }
        } else {
            alert('Заповніть Обов’язкові поля');
        }
    }


    function formValidate(form) {
        let error = 0;
        let formReq = document.querySelectorAll('._req');

        for (let index = 0; index < formReq.length; index++) {
            const input = formReq[index];
            formRemoveError(input);

            if (input.value === '') {
                formAddError(input);
                error++;
            }
        }
        return error;
    }
    function formAddError(input) {
        input.parentElement.classList.add('_error');
        input.classList.add('_error');
    }
    function formRemoveError(input) {
        input.parentElement.classList.remove('_error');
        input.classList.remove('_error');
    }
    function emailTest(input) {
        return !/^\w+([\.-]?\w+)*@\w+({\.-}?\w+)*(\.\w{2,8})+$/.test(input.value);
    }
});

SmoothScroll({
    animationTime : 800,
    stepSize : 75,
    accelerationDelta : 30,
    accelerationMax : 2,
    keyboardSupport : true,
    arrowScroll : 50,
    pulseAlgorithm : true,
    pulseScale : 4,
    pulseNormalize : 1,
    touchpadSupport : true,
})
